<template>
  <div class="container-fluid">
    <div class="calendar-app">
      <FullCalendar
        id="calendar"
        class="calendar-app-calendar calendar"
        ref="fullCalendar"
        defaultView="dayGridMonth"
        :header="{
          left: 'prev, today, next',
          center: 'title',
          right: 'listWeek'
        }"
        :plugins="calendarPlugins"
        :events="calendarEvents"
        :editable="true"
        @dateClick="handleDateClick"
        @eventClick="handleEventClick"
        :locale="$i18n.locale"
        :allDaySlot="false"
        @datesRender="handleDateChange"
        :buttonText="{today: $t('common.today')}"
      />
    </div>
    <div style="position: absolute; top: 0; right: 0; background-color: #ffffff; height: 100%; z-index: 2;">
      <transition name="bounce">
        <div v-show="eventId != null" style="width: 350px;">
          <div style="float: right; cursor: pointer;" @click="eventId = null">
            <i class="fas fa-times" style="color: red; font-size: medium; margin-top: 8px; margin-right: 5px;"></i>
          </div>
          <attendance-list-dialog ref="attendanceListDialog" :isOutSideMeeting="true" @goMeeting="goMeeting"></attendance-list-dialog>
        </div>
      </transition>
    </div>
    <confirm-modal ref="confirmModal" :content="confirmModalContent" @onConfirm="onConfirm()"></confirm-modal>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import AttendanceListDialog from "Components/AttendanceListDialog";
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment'
import ConfirmModal from 'Components/ConfirmModal'
export default {
  components: {
    FullCalendar,
    confirmModal: ConfirmModal,
    attendanceListDialog: AttendanceListDialog,
  },
  data: function() {
    return {
      calendarPlugins: [
        // plugins must be defined in the JS
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin // needed for dateClick
      ],
      calendarWeekends: true,
      calendarEvents: [],
      confirmModalContent: 'meeting.confirmChooseMeeting',
      eventId: null,
      eventTitle: ''
    };
  },
  computed: {
    ...mapGetters(['GET_EVENT_WITH_DATE_DATA'])
  },
  watch: {
    GET_EVENT_WITH_DATE_DATA: function () {
      this.calendarEvents = []
      let events = this.GET_EVENT_WITH_DATE_DATA
      for (let i = 0, size = events.length; i < size; i++) {
        let calendarEventObj = {
          id: events[i].id,
          title: events[i].name,
          start: events[i].start_datetime,
          end: events[i].end_datetime,
          textColor: "#ffffff"
        }
        this.calendarEvents.push(calendarEventObj)
      }
    }
  },
  methods: {
    // Go meeting
    goMeeting: function () {
      this.$refs.confirmModal.onShowModalConfirm(this.eventTitle)
    },
    handleDateChange: function(arg) {
      this.eventId = null
      let month = moment(arg.view.currentStart).format('MM');
      let year = moment(arg.view.currentStart).format('YYYY')
      let monthTimecurrentStart = year + "-" + month
      // console.log(monthTimecurrentStart);
      let endDatecurrentStart = moment(monthTimecurrentStart).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss')
      let statTime = moment(monthTimecurrentStart+"-"+"1").utc().format('YYYY-MM-DD HH:mm:ss');
      let endTime = endDatecurrentStart;
      let filter = {
        params: {
          calendarType: 'month',
          statTime: statTime,
          endTime: endTime
        }
      }
      this.GET_EVENT_WITH_DATE(filter)
    },
    /**
     * On confirm
     */
    onConfirm: function () {
      this.$store.state.app.inAMeeting = true
      this.$router.push({
        name: 'information.title'
      })
      sessionStorage.setItem('event_id', this.eventId);
    },
    /**
     * Handle event click
     */
    handleEventClick: function(arg) {
      this.eventId = arg.event.id
      this.$refs.attendanceListDialog.getAttendeeSpeakerList(this.eventId)
      this.eventTitle = arg.event.title
    },
    /**
     * Toggle weekends
     */
    toggleWeekends() {
      this.calendarWeekends = !this.calendarWeekends; // update a property
    },
    /**
     * Go to past
     */
    gotoPast() {
      let calendarApi = this.$refs.fullCalendar.getApi(); // from the ref="..."
      calendarApi.gotoDate("2020-03-27"); // call a method on the Calendar object
    },
    /**
     * Handle date click
     */
    handleDateClick() {
      // Todo
    },
    ...mapActions(['GET_EVENT_WITH_DATE'])
  },
  mounted() {
  }
};
</script>
<style lang="scss" scoped>
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import '../../style/color.scss';
.calendar-app {
  padding: 5px 0px 15px 0px;
  font-size: 14px;
}
.calendar-app-top {
  margin: 0 0 3em;
}
.calendar-app-calendar {
  margin: 0 auto;
  margin-top: 15px;
  width: 100%;
}
/deep/ .fc-button-primary {
  background-color: $primary !important;
  border: none;
}
/deep/ .fc-today-button {
  background-color: $light-primary !important;
}
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
