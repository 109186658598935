<template>
  <div style="border: 1px solid #e5e5e5; background-color: #ffffff; min-height: 800px">
    <div v-if="isOutSideMeeting" style="text-align: left; padding: 10px;">
      <h5>{{ eventDetail.title && eventDetail.title }}</h5>
      <div class="event-info">
        <!-- Schedule -->
        <div v-if="eventDetail.start != null && eventDetail.start != ''">
          <i class="far fa-calendar-check" style="color: orange; margin-right: 15px;"></i>
          <span>{{ formatEventDateTime(eventDetail.start, eventDetail.end) }}</span>
        </div>
        <!-- Location -->
        <div v-if="eventDetail.venue != null && eventDetail.venue != ''">
          <i class="fas fa-map-marker-alt" style="color: #007bff; margin-right: 15px;"></i>
          <span>{{ eventDetail.venue }}</span>
        </div>
        <!-- Company owner -->
        <div v-if="eventDetail.organization != null && eventDetail.organization != ''">
          <i class="fas fa-user-tie" style="color: pink; margin-right: 15px;"></i>
          <span>{{ eventDetail.organization }}</span>
        </div>
      </div>
      <div style="padding: 0px 15px 0px 15px; text-align: center;">
        <button
          type="submit"
          class="btn-login btn mt-3"
          style="width: 300px; color: #ffffff;"
          @click="goMeeting()"
          v-show="showButtonRegister==0"
        >{{ $t('attendance.joinMeeting') }}</button>
        <button
          v-show="showButtonRegister==1 && !forICTDongNai"
          type="submit"
          class="btn-login btn mt-3"
          style="width: 300px; color: #ffffff;"
          @click="onClickRegisterIntoEvent()"
        >{{ $t('register.title') }}</button>
        <div class="mt-3 font-weight-light" v-show="showButtonRegister==2">{{ $t('meeting.meetingEnd') }}</div>
      </div>
    </div>
    <div v-show="showButtonRegister==0">
      <div
        class="title text-left mr-1 ml-1"
        style="background-color: #e5e5e5; padding: 5px 10px 5px 10px;"
      >
        <span>{{ $t('meeting.chaired') }}</span>
      </div>
      <div style>
        <div v-if="chairmanData.id!=null" class="content" style="margin-left: 10px;">
          <div class="row">
            <div class="col-1" style="align-self: center;">
              <div
                v-if="chairmanData.avatar"
                style="width: 30px; height: 30px; background-color: #e5e5e5; border-radius: 50%; margin-top: 10px;"
              >
                <avatar
                  :src="chairmanData.avatar"
                  backgroundColor="#e5e5e5"
                  color="#0097A7"
                  :size="30"
                ></avatar>
              </div>
              <div v-else style="margin-top: 10px;">
                <avatar
                  :username="chairmanData.name"
                  backgroundColor="#e5e5e5"
                  color="#0097A7"
                  :size="30"
                ></avatar>
              </div>
            </div>
            <div class="col-10 pl-0">
              <div
                class="chair-info text-left ml-4"
                style="margin-top: 5px; border: 0; left: 0; right: 0;"
              >
                <span
                  :style="`display: block; font-size: small; ${chairmanData.joinEvent === joinMeetingEnum.JOIN_MEETING ? 'color: #006ad4; font-weight: bold' : 'color: #000000'}`"
                >{{ chairmanData.name }}</span>
                <span
                  style="display: block; font-size: small; color: #707070"
                >{{ getCompanyAndPosition(chairmanData.company, chairmanData.position) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="attendance-list"
      style="margin-top: 10px; background-color: #ffffff;"
      v-show="showButtonRegister==0"
    >
      <div
        class="title text-left mr-1 ml-1"
        style="padding: 5px 10px 5px 10px; background-color: #e5e5e5;"
      >
        <span>{{ $t('meeting.attendanceList') }}</span>
      </div>
      <div class="status" style="margin-top: 10px;">
        <div class="text-left">
          <div class="col-12" style="font-size: small">
            <i class="fas fa-check-circle" style="color: green; margin-right: 5px;"></i>
            <span>{{ attendanceNumber.attendance }} {{ $t('meeting.attendant') }}</span>
          </div>
          <div class="col-12" style="font-size: small; margin-top: 5px;">
            <i class="fas fa-times-circle" style="color: red; margin-right: 5px;"></i>
            <span>{{ attendanceNumber.absence }} {{ $t('meeting.absence') }}</span>
          </div>
          <div class="col-12" style="font-size: small; margin-top: 5px;">
            <i class="far fa-circle" style="color: green; margin-right: 5px;"></i>
            <span>{{ attendanceNumber.no_attendance }} {{ $t('meeting.noAttendance') }}</span>
          </div>
        </div>
      </div>
      <div
        class="list"
        style="padding: 10px; padding-top: 0px; height: 625px; max-height: 625px; overflow-y: auto; overflow-x: hidden;display: flex;flex-direction: column;justify-content: space-between;"
      >
        <div>
          <div class="row" v-for="(attendance, index) in attendanceList" :key="attendance + index">
            <!-- Aavatar -->
            <div class="col-1" style="margin-top: 10px;">
              <div v-if="attendance.avatar" style="width: 30px; height: 30px; border-radius: 50%;">
                <avatar
                  :src="attendance.avatar"
                  backgroundColor="#e5e5e5"
                  color="#0097A7"
                  :size="30"
                ></avatar>
              </div>
              <div v-else>
                <avatar
                  :username="attendance.name"
                  backgroundColor="#e5e5e5"
                  color="#0097A7"
                  :size="30"
                ></avatar>
              </div>
            </div>
            <div class="col-9 pl-0">
              <div style="display: block;" class="ml-4">
                <div class="text-left mt-2">
                  <span
                    :style="`display: block; font-size: small; ${attendance.join_event === joinMeetingEnum.JOIN_MEETING ? 'color: #006ad4; font-weight: bold' : 'color: #000000'};`"
                  >{{ attendance.name }}</span>
                  <span
                    style="display: block; font-size: small; color: #707070"
                  >{{ getCompanyAndPosition(attendance.company, attendance.position) }}</span>
                </div>
              </div>
            </div>
            <div class="mt-2" style="display: flex; justify-content: flex-end">
              <i
                v-show="attendance.attendance==1"
                class="fas fa-check-circle"
                style="color: green;"
              ></i>
              <i v-show="attendance.attendance==0" class="far fa-circle" style="color: green;"></i>
              <i v-show="attendance.attendance==2" class="fas fa-times-circle" style="color: red;"></i>
            </div>
          </div>
        </div>
        <div class="bottom mt-3">
          <pagination
            v-if="attendanceList.length > 0"
            :rows="attendanceListPaginate.total"
            :per-page="attendanceListPaginate.perPage"
            :current-page="attendanceListPaginate.currentPage"
            @onPageChange="onAttendanceListPageChange"
          ></pagination>
        </div>
      </div>
    </div>
    <div>
      <b-modal
        v-model="showApprovedWaiting"
        :title="$t('sideBar.notification')"
        centered
        id="modal-center"
      >
        <div class="container">
          <div class="row">
            <p>{{ $t('common.approvedWaiting') }}</p>
          </div>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              class="float-right"
              style="background-color: #fff; color: #000; border: none;"
              @click="showApprovedWaiting = false"
            >{{ $t('common.close') }}</b-button>
          </div>
        </template>
      </b-modal>
    </div>
    <div>
      <b-modal v-model="showRegisterConfirmModel" :title="$t('common.confirm')" centered id="modal-center">
        <div class="container">
          <div class="row">
            {{ $t('meeting.confirmRegisterMeeting') }}
          </div>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="float-right btn-submit ml-3"
              style="border: none;"
              @click="onClickRegisterConfirm()"
            >{{ $t('common.accept') }}</b-button>
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              style="background-color: #fff; color: #000; border: none;"
              @click="showRegisterConfirmModel = false"
            >{{ $t('common.close') }}</b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Avatar from "vue-avatar";
import dateUtils from "utils/dateUtils";
import functionUtils from "utils/functionUtils";
import moment from "moment";
import Enum from "enum/enum"
export default {
  components: {
    avatar: Avatar,
  },
  props: {
    isOutSideMeeting: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data: () => ({
    attendanceList: [],
    numberOfAbsent: 0,
    numberOfNotAbsent: 0,
    attendanceListPaginate: {
      total: 1,
      perPage: 1,
      currentPage: 1,
    },
    eventDetail: {
      title: "",
      start: "",
      end: "",
      venue: "",
      organization: "",
    },
    attendanceNumber: {
      attendance: 0,
      no_attendance: 0,
      absence: 0,
    },
    chairmanData: {
      id: 0,
      name: "",
      position: "",
      avatar: "",
      company: "",
      joinEvent: null
    },
    eventID: null,
    showApprovedWaiting: false,
    showButtonRegister: 0, // 0: show btn join event, 1: show btn register, 2: hide all
    checkCurrentUserWaitApproved: false,
    showRegisterConfirmModel: false,
    forICTDongNai: process.env.VUE_APP_IS_FOR_DONGNAI.trim() == 'true' ? true : false,
    joinMeetingEnum: Enum.JoinMeetingEnum
  }),
  watch: {
    EVENT_DETAIL_DATA: function () {
      let eventDetail = this.EVENT_DETAIL_DATA;
      this.eventDetail = {
        title: eventDetail.name,
        start: dateUtils.formatDate(
          eventDetail.start_datetime,
          dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
          "YYYY-MM-DD HH:mm"
        ),
        end: dateUtils.formatDate(
          eventDetail.end_datetime,
          dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
          "YYYY-MM-DD HH:mm"
        ),
        venue: eventDetail.venue,
        organization: eventDetail.organization_data.name,
      };
    },
    GET_ATTENDANCE_NUMBER_DATA: function () {
      this.attendanceNumber.attendance = this.GET_ATTENDANCE_NUMBER_DATA.attendance;
      this.attendanceNumber.no_attendance = this.GET_ATTENDANCE_NUMBER_DATA.no_attendance;
      this.attendanceNumber.absence = this.GET_ATTENDANCE_NUMBER_DATA.absence;
    },
    GET_USER_IN_EVENT_LIST_DATA: function () {
      // Paginate
      this.attendanceListPaginate.total = this.GET_USER_IN_EVENT_LIST_DATA.paginate.totalPage;
      this.attendanceListPaginate.perPage = 1;
      this.attendanceListPaginate.currentPage = this.GET_USER_IN_EVENT_LIST_DATA.paginate.currentPage;

      this.attendanceList = [];
      let dataList =
        this.GET_USER_IN_EVENT_LIST_DATA.results != undefined
          ? this.GET_USER_IN_EVENT_LIST_DATA.results
          : this.GET_USER_IN_EVENT_LIST_DATA;
      dataList.forEach((data) => {
        let full_name = data.lastname + " " + data.firstname;
        let attendee_position = data.company_position;
        let item = {
          name: full_name,
          position: attendee_position,
          company: data.company_name,
          avatar:
            data.url_avatar != null || data.url_avatar != ""
              ? data.url_avatar
              : null,
          attendance: data.user_in_event.attendance,
          join_event: data.user_in_event.join_event
        };
        this.attendanceList.push(item);
      });
    },
    GET_CHAIRMAN_FOR_MEETING_DATA: function () {
      this.chairmanData.id = this.GET_CHAIRMAN_FOR_MEETING_DATA.id;
      this.chairmanData.name = this.GET_CHAIRMAN_FOR_MEETING_DATA.full_name;
      this.chairmanData.position = this.GET_CHAIRMAN_FOR_MEETING_DATA.company_position;
      this.chairmanData.avatar =
        this.GET_CHAIRMAN_FOR_MEETING_DATA.url_avatar != null ||
        this.GET_CHAIRMAN_FOR_MEETING_DATA.url_avatar != ""
          ? this.GET_CHAIRMAN_FOR_MEETING_DATA.url_avatar
          : null;
      this.chairmanData.company = this.GET_CHAIRMAN_FOR_MEETING_DATA.company_name;
      this.chairmanData.joinEvent = this.GET_CHAIRMAN_FOR_MEETING_DATA.join_event
    },
    CALLBACK_API_ATTENDANCE: function () {
      if (this.$route.name === 'information.title' || this.$route.name === 'attendance.title'){
        this.onAttendanceListPageChange(this.attendanceListPaginate.currentPage)
        let filter = {
          params: { 
            event: sessionStorage.getItem("event_id")
          }
        }
        this.GET_CHAIRMAN_FOR_MEETING(filter)
        this.GET_ATTENDANCE_NUMBER(filter)
      }
    }
  },
  computed: {
    ...mapGetters([
      "GET_ATTENDEE_DATA",
      "EVENT_DETAIL_DATA",
      "GET_ATTENDANCE_NUMBER_DATA",
      "GET_USER_IN_EVENT_LIST_DATA",
      "GET_CHAIRMAN_FOR_MEETING_DATA",
      "CALLBACK_API_ATTENDANCE"
    ]),
  },
  methods: {
    formatEventDateTime: function(startTime, endTime){
      return functionUtils.formatEventDateTime(startTime, endTime)
    },
    onAttendanceListPageChange: function (page) {
      this.attendanceListPaginate.currentPage = page;
      const customer_data = {
        params: {
          page: this.attendanceListPaginate.currentPage,
          event: this.eventID,
          exceptChairman: true,
        },
      };
      this.GET_USER_IN_EVENT_LIST(customer_data);
    },
    getCompanyAndPosition: function (company, position) {
      return functionUtils.getCompanyAndPosition(company, position);
    },
    /**
     * Go to meeting
     */
    goMeeting: function () {
      this.$emit("goMeeting");
    },
    /**
     * Get attendee and speaker list
     */
    getAttendeeSpeakerList: function(eventId, state_id) {
      if (state_id == 1) {
        let filter = {
          params: {
            event_id: eventId,
          },
        };
        this.CHECK_USER_IN_EVENT(filter)
          .then(
            function () {
              this.showButtonRegister = 0;
              this.checkCurrentUserWaitApproved = false;
              this.eventID = eventId;
              let event = {
                params: {
                  is_meeting: true
                },
                id: eventId, 
              }
              this.GET_EVENT_DETAIL(event);
              const customer_data = {
                params: {
                  event: eventId,
                  exceptChairman: true,
                  getAllUserInGroup: true,
                  page: 1,
                },
              };
              this.GET_ATTENDANCE_NUMBER(customer_data);
              this.GET_USER_IN_EVENT_LIST(customer_data);
              this.GET_CHAIRMAN_FOR_MEETING(customer_data);
            }.bind(this)
          )
          .catch(
            function(error) {
              let mess = error.response.data.message;
              this.attendanceList = [];
              this.chairmanData = {
                id: 0,
                name: "",
                position: "",
                avatar: "",
                company: "",
              },
              this.attendanceNumber = {
                attendance: 0,
                no_attendance: 0,
                absence: 0,
              },
              this.eventID = eventId;
              let event = {
                params: {
                  is_meeting: true
                },
                id: eventId, 
              }
              this.GET_EVENT_DETAIL(event)
              .then(
                function(res){
                  let data = res.data;
                  let eventEndTime = dateUtils.formatDate(
                    data.end_datetime,
                    dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T,
                    "YYYY-MM-DD"
                  );
                  let currentDate = moment().format("YYYY-MM-DD");
                  if (currentDate > eventEndTime) {
                    this.showButtonRegister = 2;
                  }
                  else {
                    if (mess == "approval_waiting") {
                      // show notification waiting approval
                      this.checkCurrentUserWaitApproved = true;
                      this.showButtonRegister = 1;
                    } else {
                      // show button register meeting
                      this.checkCurrentUserWaitApproved = false;
                      this.showButtonRegister = 1;
                    }
                  }
                }.bind(this)
              )
              .catch(
                function(){

                }.bind(this)
              )
            }.bind(this)
          );
      } else {
        this.showButtonRegister = 0;
        this.checkCurrentUserWaitApproved = false;
        this.eventID = eventId;
        let event = {
          params: {
            is_meeting: true
          },
          id: eventId, 
        }
        this.GET_EVENT_DETAIL(event);
        const customer_data = {
          params: {
            event: eventId,
            exceptChairman: true,
            getAllUserInGroup: true,
            page: 1,
          },
        };
        this.GET_ATTENDANCE_NUMBER(customer_data);
        this.GET_USER_IN_EVENT_LIST(customer_data);
        this.GET_CHAIRMAN_FOR_MEETING(customer_data);
      }
    },
    compareDate: function (start, end) {
      if (start === end) {
        return start;
      } else return start + " - " + end;
    },
    //   let startTime = moment(start)
    //   let endTime = moment(end)
    //   if (startTime.format("DD-MM-YYYY") === endTime.format("DD-MM-YYYY")) {
    //     return startTime.format("DD/MM/YYYY");
    //   } else return startTime.format("DD/MM/YYYY") + " - " + endTime.format("DD/MM/YYYY");
    // },
    onClickRegisterIntoEvent() {
      if (this.checkCurrentUserWaitApproved) {
        this.showApprovedWaiting = true;
      }
      else{
        this.showRegisterConfirmModel = true;
      }
    },
    onClickRegisterConfirm(){
      let data = {
        event_id: this.eventID,
        site_name: this.$t('common.appTitle')
      }
      this.REGISTER_USER_IN_EVENT(data)
        .then(
          function () {
            this.checkCurrentUserWaitApproved = true;
          }.bind(this)
        )
        .catch(
          function(){
            this.checkCurrentUserWaitApproved = true;
          }.bind()
        )
      this.showApprovedWaiting = true;
      this.showRegisterConfirmModel = false;
    },
    ...mapActions([
      "GET_EVENT_DETAIL",
      "GET_ATTENDANCE_NUMBER",
      "GET_USER_IN_EVENT_LIST",
      "GET_CHAIRMAN_FOR_MEETING",
      "CHECK_USER_IN_EVENT",
      "REGISTER_USER_IN_EVENT",
    ]),
  },
  mounted() {
    if (!this.isOutSideMeeting) {
      let event_id = sessionStorage.getItem("event_id");
      this.eventID = event_id;
      const customer_data = {
        params: {
          page: 1,
          event: event_id,
          exceptChairman: true,
          getAllUserInGroup: true,
        },
      };

      this.GET_ATTENDANCE_NUMBER(customer_data);
      this.GET_USER_IN_EVENT_LIST(customer_data);
      this.GET_CHAIRMAN_FOR_MEETING(customer_data);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../style/color.scss";
.info {
  padding: 10px;
}
.circular--portrait {
  position: relative;
  max-width: 52px;
  max-height: 52px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.circular--portrait img {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.btn-login {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
  border-radius: 5px;
}
.btn-submit {
    background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
    background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
    background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
    background: -webkit-gradient(linear,left bottom,left top,color-stop(0.3, $light-primary), color-stop(0.7, $primary));
    border-radius: 5px;
}
</style>
